<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Manage Add/Edit Courses</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <validation-observer ref="">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information" active>
                <b-form @submit.prevent="onSubmit()">
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <h2>Course Information</h2>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="Reference No." label-for="v-refnum">
                        <b-form-input
                          disabled
                          id="v-refnum"
                          v-model="formData.refnum"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Training Code"
                        label-for="v-new_code"
                      >
                        <b-form-input
                          id="v-new_code"
                          v-model="formData.new_code"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Name" label-for="v-name">
                        <b-form-input id="v-name" v-model="formData.name" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Partner" label-for="v-partner">
                        <b-form-select
                          id="v-partner"
                          v-model="formData.partner"
                          text-field="name"
                          value-field="name"
                          :options="partners"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Subject" label-for="v-subject">
                        <b-form-select
                          id="v-subject"
                          v-model="formData.subject"
                          text-field="name"
                          value-field="name"
                          :options="subjects"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Level" label-for="v-level">
                        <b-form-input
                          id="v-level"
                          v-model="formData.level"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Category" label-for="v-category">
                        <b-form-input
                          id="v-category"
                          v-model="formData.category"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Mode" label-for="v-mode">
                        <b-form-input
                          id="v-mode"
                          v-model="formData.mode"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="URL" label-for="v-url">
                        <b-form-input
                          id="v-url"
                          v-model="formData.url"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Question Bank"
                        label-for="v-question_bank"
                      >
                        <b-form-input
                          id="v-question"
                          v-model="formData.question_bank"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Cost" label-for="v-cost">
                        <b-form-input
                          id="v-cost"
                          :class="{
                            'border-danger':
                              formData.cost && !correctDecimalFormat.cost,
                          }"
                          class="text-right"
                          v-model="formData.cost"
                          @change="convertToDecimal('cost')"
                          @input="incorrectDecimal('cost')"
                          @keypress="isNumber"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Training Status"
                        label-for="v-training_status"
                      >
                        <b-form-select
                          id="v-training_status"
                          :options="statuses"
                          v-model="formData.training_status"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Old Code"
                        label-for="v-training_uuid"
                      >
                        <b-form-select
                          id="v-training_uuid"
                          v-model="formData.training_uuid"
                          :options="trainings"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Description"
                        label-for="v-description"
                      >
                        <quill-editor
                          ref="quillEditorRef"
                          v-model="content"
                          id="editor"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Type of Certification"
                        label-for="v-type_of_certification"
                      >
                        <b-form-select
                          id="v-type_of_certification"
                          v-model="formData.type_of_certification"
                          @change="
                            (e) => {
                              if (e === 'free') {
                                formData.certification_amount = '0.00';
                              }
                            }
                          "
                          :options="[
                            { text: 'Free', value: 'free' },
                            { text: 'Paid', value: 'paid' },
                          ]"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Certification Amount"
                        label-for="v-certification_amount"
                      >
                        <b-form-input
                          :disabled="formData.type_of_certification !== 'paid'"
                          id="v-certification_amount"
                          :class="{
                            'border-danger':
                              formData.certification_amount &&
                              !correctDecimalFormat.certification_amount,
                          }"
                          class="text-right"
                          v-model="formData.certification_amount"
                          @change="convertToDecimal('certification_amount')"
                          @input="incorrectDecimal('certification_amount')"
                          @keypress="isNumber"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Registration Link"
                        label-for="v-registration_link"
                      >
                        <b-form-input
                          type="url"
                          id="v-registration_link"
                          v-model="formData.registration_link"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Certificate"
                        label-for="v-certificate"
                      >
                        <b-form-file
                          id="v-certificate"
                          v-model="fileUpload"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Certificate Logo" label-for="v-logo">
                        <b-form-file
                          id="v-logo"
                          v-model="logoUpload"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6" v-if="imagePreview">
                      <b-form-group label="Preview">
                        <b-img center thumbnail fluid :src="imagePreview" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" v-if="logoPreview">
                      <b-form-group label="Preview">
                        <b-img center thumbnail fluid :src="logoPreview" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-right">
                      <b-button
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                        @click="goBack()"
                      >
                        Back
                      </b-button>
                      <b-button
                        :disabled="
                          isLoading ||
                          !correctDecimalFormat.cost ||
                          !correctDecimalFormat.certification_amount
                        "
                        v-b-modal.tab-information
                        type="button"
                        variant="primary"
                      >
                        {{ onUpdate }}
                      </b-button>

                      <Modal
                        modalId="tab-information"
                        @onChange="(v) => onSubmit()"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
              <span v-if="$route.query.id">
                <b-tab title="Logs">
                  <Logs ref="logsRef" module="trainings" />
                </b-tab>
                <b-tab title="Comments">
                  <Comments ref="commentsRef" module="trainings" />
                </b-tab>
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import trainingSubjectsApi from '@api/training_subjects';
import trainingPartnersApi from '@api/training_partners';
import trainingApi from '@api/trainings';
import statusesApi from '@api/statuses';
import { queryParameters } from '@/schema';

export default {
  computed: {
    onUpdate: {
      get() {
        return this.formData.id ? 'Update' : 'Create';
      },
    },
    imagePreview: {
      get() {
        const { id, certificate, base64 } = this.formData;
        const { VUE_APP_ROOT_STATIC_URL: staticURL } = process.env;

        if (base64) {
          return base64;
        }

        if (!base64 && certificate) {
          console.log(`${staticURL}/trainings/${id}/${certificate}`);
          return `${staticURL}/trainings/${id}/${certificate}`;
        }

        return null;
      },
    },
    logoPreview: {
      get() {
        const { id, certification_logo, logobase64 } = this.formData;
        const { VUE_APP_ROOT_STATIC_URL: staticURL } = process.env;

        if (logobase64) {
          return logobase64;
        }

        if (!logobase64 && certification_logo) {
          console.log(`${staticURL}/trainings/${id}/${certification_logo}`);
          return `${staticURL}/trainings/${id}/${certification_logo}`;
        }

        return null;
      },
    },
  },
  watch: {
    activeTab(v) {
      if (v === 1) {
        this.$refs.logsRef.loadLogs();
      }

      if (v === 2) {
        this.$refs.commentsRef.loadTimelineHeader();
      }
    },
    fileUpload(e) {
      if (e) {
        const reader = new window.FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          this.formData.certificate = e.name;
          this.formData.base64 = reader.result;
        };
      }
    },
    logoUpload(e) {
      if (e) {
        const reader = new window.FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          this.formData.certification_logo = e.name;
          this.formData.logobase64 = reader.result;
        };
      }
    },
  },
  data: () => ({
    activeTab: 0,
    correctDecimalFormat: {
      cost: false,
      certification_amount: false,
    },
    isLoading: false,
    subjects: [],
    partners: [],
    statuses: [],
    trainings: [],
    fileUpload: null,
    logoUpload: null,
    content: '',
    formData: {
      id: 0,
      partner_uuid: '',
      training_uuid: '',
      name: '',
      partner: '',
      subject: '',
      level: '',
      description: '',
      category: '',
      mode: '',
      url: '',
      question_bank: '',
      cost: '0.00',
      training_status: '',
      new_code: '',
      type_of_certification: '',
      certification_amount: '0.00',
      certification_logo: null,
      registration_link: '',
      certificate: '',
      refnum: '',
      base64: null,
      logobase64: null,
    },
  }),
  mounted() {
    this.loadSystemCode();
    this.loadTrainingSubjects();
    this.loadTrainingPartners();

    if (+this.$route.query.id) {
      this.loadTraining();
    }

    this.loadTrainings();
    this.incorrectDecimal('cost');
    this.incorrectDecimal('certification_amount');
  },
  methods: {
    loadTrainings() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      trainingApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            const items = [];
            for (const item of data) {
              if (this.formData && this.formData.new_code !== item.new_code) {
                items.push({ text: item.name, value: item.new_code });
              }
            }

            this.trainings = items;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadTraining() {
      trainingApi
        .get(this.$route.query.id)
        .then(({ data, meta }) => {
          if (data != null) {
            const { quill } = this.$refs.quillEditorRef;
            if (quill) {
              quill.root.innerHTML = data.description;
            }
            this.content = data.description;

            data.cost = data.cost.toFixed(2);
            data.certification_amount = data.certification_amount.toFixed(2);
            this.formData = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadTrainingSubjects() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      trainingSubjectsApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.subjects = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadTrainingPartners() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      trainingPartnersApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.partners = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSystemCode() {
      const params = {
        sort: '-created_at',
        page: { size: 1000 },
        status: 'O',
        filterExt: "code='trainingstatus'",
      };

      statusesApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            const [item] = data;
            this.statuses = item.selections;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      this.isLoading = true;
      let app;

      this.formData.cost = parseFloat(this.formData.cost);
      this.formData.certification_amount = parseFloat(
        this.formData.certification_amount
      );
      this.formData.description = this.content;

      if (this.formData.id) {
        app = trainingApi.update(this.formData);
      } else {
        app = trainingApi.add(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            const url = `/pages/masterlists/trainings/view?id=${data.id}`;
            this.isLoading = false;
            this.toastConfig();
            this.$router.push(url);
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    convertToDecimal(field) {
      const amount = this.formData[field];

      if (amount != '' && this.correctMoneyFormat(amount)) {
        const toDecimal = this.moneyFormat(amount).replace('$', '');

        const valueIsNan = isNaN(toDecimal.replaceAll(',', ''));
        this.formData[field] = valueIsNan ? '0.00' : toDecimal;

        return;
      }

      this.formData[field] = '0.00';
      this.incorrectDecimal(field);
    },
    incorrectDecimal(field) {
      const amount = this.formData[field];
      this.correctDecimalFormat[field] = this.correctMoneyFormat(amount);
    },
    isNumber(event) {
      if (isNaN(event.key) && event.key !== '.' && event.key !== 'Backspace') {
        event.preventDefault();
      }

      if (event.code === 'Space') {
        event.preventDefault();
      }
    },
    goBack() {
      this.$router.push('/pages/masterlists/trainings');
    },
  },
};
</script>

<style></style>
